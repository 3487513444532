import { createCaptcha, validateCaptcha } from '@api/handlers/captcha';
import { useMountEffect } from '@hooks/useMountEffect';
import { t } from '@lib/i18n';
import { FieldBuilder } from '@ui/FieldBuilder';
import IconButton from '@ui/IconButton';
import { Modal } from '@ui/Modal';
import { SubmitForm } from '@ui/SubmitForm';
import Typography from '@ui/Typography';
import { useCallback, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import RefreshIcon from '../../../../static/images/refresh_icon.svg';

const CaptchaModal = ({ isOpen, onResolve, onReject }) => {
  const [captcha, setCaptcha] = useState(null);
  const form = useForm({ mode: 'onChange' });

  const init = useCallback(() => {
    createCaptcha().then(({ responseBody }) => {
      setCaptcha(responseBody.data);
    });
  }, []);

  useMountEffect(init);

  const onRetryClick = useCallback(() => {
    createCaptcha()
      .then(({ responseBody }) => {
        setCaptcha(responseBody.data);
        form.reset();
      });
  }, [form]);

  const handleSubmit = useCallback((values) => {
    if (captcha) {
      validateCaptcha({
        data: values.code,
        uuid: captcha.uuid,
      })
        .then(({ status }) => {
          if (status === 400) {
            onRetryClick();
          } else {
            onResolve({
              data: values.code,
              uuid: captcha.uuid,
            });
          }
        });
    }
  }, [captcha, onResolve, onRetryClick]);

  const onClose = () => {
    onReject();
  };

  return (
    captcha && (
      <Modal
        visible={isOpen}
        name="captcha"
        onClose={onClose}
        className="sm:!w-full md:!w-[554px] lg:!w-[470px]"
        title={t('modals.captcha.title')}
        titleClassname="mb-4 md:mb-3"
      >
        <Typography className="mb-3 hidden text-secondary400 sm:block" as="h3" variant="header3">
          {t('modals.captcha.title')}
        </Typography>
        <div className="mb-3 flex">
          <img src={captcha.data} alt="" className="w-[180px]" />
          <button
            type="button"
            onClick={onRetryClick}
            className="ml-2.5 bg-transparent"
          >
            <RefreshIcon className="stroke-secondary400 hover:stroke-primary400 focus:stroke-primary400" />
          </button>
        </div>
        <SubmitForm
          form={form}
          submitText={t('modals.captcha.button_name')}
          onSubmit={handleSubmit}
          className="flex flex-col"
          buttonClassName="w-auto"
        >
          <FieldBuilder.Text
            name="code"
            label=""
            rules={{
              required: t('field_errors.required'),
            }}
            maxLength="4"
            type="tel"
          />
        </SubmitForm>
      </Modal>
    )
  );
};

export default CaptchaModal;
