import {
  CompanyPrivacyLink, CompanySaasLink, 
} from '@entities/company';
import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import Checkbox from '@ui/Checkbox/Checkbox';
import MediaQuery from '@ui/MediaQuery';
import Typography, { getTypographyClass } from '@ui/Typography';
import './PrivacyFooter.scss';

const { block } = bem('PrivacyFooter');

const FooterText = ({ screen }) => {
  return (
    <Typography className="mt-1 text-deep-blue-5" variant="caption1">
      {t(`registration.privacy_footer_text.${screen}`)}
    </Typography>
  );
};

const PrivacyFooter = ({
  checked, onChange,
}) => {
  return (
    <div {...block()}>
      {
        ['sm', 'md', 'lg', 'xl'].map((x, i) => (
          <MediaQuery at={x} key={i}>
            <div>
              <Checkbox 
                onChange={onChange} 
                checked={checked} 
                label={t('registration.privacy_checkbox_label')}
                saas={(
                  <CompanySaasLink
                    className={getTypographyClass({
                      variant: 'link2',
                      className: 'text-blue-2',
                    })}
                  />
                )} 
                privacyLink={(
                  <CompanyPrivacyLink
                    className={getTypographyClass({
                      variant: 'link2',
                      className: 'text-blue-2',
                    })}
                  />
                )}
              />
              <FooterText screen={x} />
            </div>
          </MediaQuery>
        ))
      }
    </div>
  );
};

export default PrivacyFooter;
