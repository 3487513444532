import { RequestFeedback } from '@features/request-feedback';

import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import { useModal } from '@ui/Modal';
import StaticIcon from '@ui/StaticIcon';
import Typography, { getTypographyClass } from '@ui/Typography';
import { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';

import './style.scss';

const {
  block,
  element,
} = bem('banner');

const Banner = () => {
  // const { login } = webAppModel({});
  const { openModal } = useModal('request-demo');

  const [smallScreenHeight, setSmallScreenHeight] = useState(false);

  const handleWindowResize = useCallback(() => {
    setSmallScreenHeight(window.innerHeight < 860);
  }, []);

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', handleWindowResize);
  }

  return (
    <div {...block()}>
      <RequestFeedback.Demo.Modal />
      <div {...element('inner')}>
        <div {...element('flex-scroll')}>
          <div {...element('content')}>
            <div>
              <div>
                <Typography
                  variant="caption1"
                  uppercase
                  color="deepBlue5"
                >
                  {t('registration.banner.title')}
                </Typography>
                <Typography
                  {...element('subtitle')}
                  variant="header5"
                  color="deepBlue1"
                >
                  {t('registration.banner.subtitle')}
                </Typography>
              </div>
              <div {...element('screen-image-wrapper', { smallScreenHeight })}>
                <StaticIcon name="functional-icons" />
              </div>
              <div {...element('functional-text', { smallScreenHeight })}>
                {t('registration.banner.functional')
                  .map((item, i) => {
                    return item === 'dot'
                      ? <div key={i} className="mb-1 h-[2px] w-[2px] rounded-full bg-blue-2 sm:hidden" />
                      : (
                        <Typography key={item} variant="caption2" className="mr-1 mb-1 text-deep-blue-5">
                          {item}
                        </Typography>
                      );
                  })}
              </div>
            </div>
            <Typography
              {...element('cta')}
              color="secondary100"
            >
              <Trans
                i18nKey="registration.banner.cta"
                components={{
                  login: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                    <a
                      className={getTypographyClass({
                        variant: 'link1',
                        className: 'text-blue-2',
                      })}
                      href="#"
                      onClick={openModal}
                    />
                  ),
                }}
              />
            </Typography>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
