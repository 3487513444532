import Button from '@ui/Button';
import Typography from '@ui/Typography';

const ReplaceMobileButton = ({ onClick, children }) => {
  return (
    <Button
      invisible
      onClick={onClick}
      className="mx-auto"
    >
      <Typography
        variant="button2"
        weight="medium"
        color="primary400"
      >
        {children}
      </Typography>
    </Button>
  );
};

export default ReplaceMobileButton;
