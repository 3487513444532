import { LoginButton } from '@features/login-button';
import { TextLogo } from '@ui/Logo';
import { Header } from '@widgets/layout';

const RegisterPageHeader = (props) => {
  return (
    <Header {...props}>
      <div style={{ width: '400px' }}>
        <TextLogo />
      </div>
      <LoginButton />
    </Header>
  );
};

export default RegisterPageHeader;
