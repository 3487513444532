import PropTypes from 'prop-types';
import { bem } from '../../lib/bem';

import './style.scss';

const {
  block,
  element,
} = bem('wizard-progress');

const WizardProgress = ({
  stepsNumber,
  currentStepIndex,
  className,
}) => {
  const steps = [...Array(stepsNumber)
    .keys()].map((i) => i);
  return (
    <div {...block({}, className)}>
      {steps.map((index) => {
        return (
          <div
            key={index}
            {...element('step', { active: index === currentStepIndex })}
          />
        );
      })}
    </div>
  );
};

WizardProgress.defaultProps = {
  className: '',
};

WizardProgress.propTypes = {
  stepsNumber: PropTypes.number.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default WizardProgress;
