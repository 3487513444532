import { getCaptcha } from '@api/handlers/captcha';
import { CaptchaModal } from '@widgets/captcha';
import {
  useCallback, useState,
} from 'react';
import { create } from 'react-modal-promise';
import { useMountEffect } from './useMountEffect';

export const useCaptcha = () => {
  const [captchaDisabled, setCaptchaDisabled] = useState(true);

  const init = useCallback(() => {
    getCaptcha().then(({ status, responseBody }) => {
      setCaptchaDisabled(status === 421 && responseBody && responseBody.code === 2002);
    });
  }, []);

  useMountEffect(init);

  if (captchaDisabled) {
    return function () {
      return Promise.resolve({});
    };
  }

  return create(CaptchaModal);
};
