import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import MediaQuery from '@ui/MediaQuery';

import Typography, { getTypographyClass } from '@ui/Typography';
import WizardProgress from '@ui/WizardProgress';
import { Layout } from '@widgets/layout';
import { Link as GatsbyLink } from 'gatsby';

import Banner from 'routes/registration/ui/layout/banner';
import PropTypes from 'prop-types';
import './registration-layout.scss';
import RegisterPageHeader from '../header/RegisterPageHeader';

const {
  block,
  element,
} = bem('registration-layout');

const RegistrationLayout = ({
  children,
  cta,
  className,
  extraActionText,
  extraActionRoute,
  stepIndex,
  pageDefinition,
  formClassName,
  onExtraAction,
  seo,
  withScrollEffect,
}) => {
  return (
    <Layout
      showFooter={false}
      seo={seo}
      appBarProps={{
        fullWidth: true,
        containerClassName: 'md-up:!px-4',
      }}
      withScrollEffect={withScrollEffect}
      HeaderComponent={RegisterPageHeader}
    >
      <div {...block(className)}>
        <Banner />
        <div {...element('main-wrap')}>
          <div {...element('page-wrap')}>
            <div {...element('form-container')}>
              <div
                {...element(
                  'form',
                  { withPageDefinition: !!pageDefinition },
                  formClassName,
                )}
              >
                {pageDefinition && (
                  <MediaQuery greaterThan="sm">
                    <div {...element('page-definition')}>{pageDefinition}</div>
                  </MediaQuery>
                )}
                <div {...element('form-header')}>
                  <Typography variant="header2" color="deepBlue1">
                    {t('registration.title')}
                  </Typography>
                  <Typography color="deepBlue3" {...element('form-cta')}>
                    {t('registration.subtitle')}
                  </Typography>
                </div>
                {children}
                {extraActionText && (
                  <div {...element('extra-action')}>
                    {extraActionRoute || onExtraAction ? (
                      <GatsbyLink
                        {...element('extra-action-link', {}, getTypographyClass({
                          variant: 'button2',
                          color: 'blue2',
                          center: true,
                        }))}
                        to={extraActionRoute}
                        onClick={onExtraAction}
                      >
                        {extraActionText}
                      </GatsbyLink>
                    ) : (
                      <Typography
                        color="deepBlue2"
                        variant="button2"
                        {...element('extra-action-text')}
                      >
                        {extraActionText}
                      </Typography>
                    )}
                  </div>
                )}
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

RegistrationLayout.defaultProps = {
  className: '',
  extraActionText: '',
  onExtraAction: null,
  bannerClassName: '',
  wizardClassName: '',
  pageDefinition: '',
  formClassName: '',
  extraActionRoute: '',
};

RegistrationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  cta: PropTypes.string.isRequired,
  className: PropTypes.string,
  extraActionText: PropTypes.string,
  onExtraAction: PropTypes.func,
  extraActionRoute: PropTypes.string,
  stepIndex: PropTypes.number.isRequired,
  bannerClassName: PropTypes.string,
  wizardClassName: PropTypes.string,
  pageDefinition: PropTypes.string,
  formClassName: PropTypes.string,
  seo: PropTypes.object.isRequired,
};

export default RegistrationLayout;
