import { block } from 'bem-cn';
import { useMemo } from 'react';

import './Checkbox.scss';
import { Trans } from 'react-i18next';
import Typography, { getTypographyClass } from '../Typography';

const uniqId = () => new Date().getTime();
const cn = block('checkbox');

const Checkbox = ({
  checked,
  onChange,
  label,
  privacyLink,
  saas,
}) => {
  const idCheckBox = useMemo(() => uniqId(), [label]);

  return (
    <div className={cn('container')}>
      <input
        id={idCheckBox}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={cn('input')}
      />
      <label
        htmlFor={idCheckBox}
        className={cn('label')}
      >
        <Typography variant="caption1" className={cn('label')}>
          <Trans
            i18nKey={label}
            components={{
              saas,
              privacy: privacyLink,
            }}
          />
        </Typography>
      </label>
    </div>
  );
};

export default Checkbox;
