import { useState, useCallback } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { t } from '@lib/i18n';
import { formValidators } from '@lib/form-validators';
import { sendConfirmationCode } from '@api/handlers/auth';
import { Container as ModalContainer } from 'react-modal-promise';
import { SubmitForm } from '@ui/SubmitForm';
import { FieldBuilder, FormField } from '@ui/FieldBuilder';
import StaticIcon from '@ui/StaticIcon';
import Notice from '@lib/notifications';
import PrivacyFooter from '@widgets/privacy-footer';
import Typography from '@ui/Typography';
import { useCaptcha } from '@hooks/useCaptcha';
import RegistrationLayout from '../ui/layout';
import ConfirmCode from '../ui/ConfirmCode';

const formFieldLabel = (key) => t(`registration.fields.${key}`);

const RegistrationPage = () => {
  const [isCodeSend, setSendCode] = useState(false);
  const [mobile, setMobile] = useState();
  const [data, setData] = useState();
  const [secondsUntilCanSendCode, setSecondsUntilCanSendCode] = useState();
  const [checked, setChecked] = useState(false);
  const showCaptchaModal = useCaptcha();

  const toggleCheckbox = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const form = useForm({ mode: 'onChange' });

  const canSendCode = !secondsUntilCanSendCode || secondsUntilCanSendCode === 0;

  const startTimer = (initialValue) => {
    setSecondsUntilCanSendCode(initialValue);
    const interval = setInterval(() => {
      setSecondsUntilCanSendCode((oldValue) => {
        if (oldValue === 0) {
          clearInterval(interval);
          return 0;
        }
        return oldValue - 1;
      });
    }, 1000);
  };

  const sendCode = async (values) => {
    showCaptchaModal()
      .then(async (captcha) => {
        const {
          responseBody,
          ok,
        } = await sendConfirmationCode({
          data: {
            mobile: values.mobile.number,
            action: 'confirm_mobile',
            captcha_uuid: captcha.uuid,
            captcha_data: captcha.data,
          },
        });

        let error = '';
        if (!ok) {
          if (responseBody && responseBody.message) {
            error = responseBody.message;
          } else {
            error = t('registration.field_errors.failed_code_send');
          }
          Notice.error(error, { className: 'registration-layout-toast' });
        } else {
          setData(values);
          setMobile(values.mobile);
          setSendCode(true);
          startTimer(responseBody.data.next_retry_timer);
        }
      })
      .catch((err) => console.error(err));
  };

  const { isValid } = useFormState({ control: form.control });

  const onBackClick = () => {
    setSendCode(false);
  };

  return (
    <RegistrationLayout 
      seo={t('seo.registration')}
      // Footer={!isCodeSend && <PrivacyFooter onChange={toggleCheckbox} checked={checked} />}
    >
      <ModalContainer />
      <SubmitForm
        form={form}
        onSubmit={sendCode}
        submitText={t('registration.buttons.end_registration')}
        validBeforeSubmit
        dontShowSubmit={isCodeSend}
        canSubmit={checked}
      >
        <FieldBuilder.Text
          name="managed_object_name"
          label={formFieldLabel('managed_object_name')}
          rules={{
            required: t('field_errors.required'),
          }}
          showError
          disabled={isCodeSend}
          maxLength="60"
        />
        <FieldBuilder.Text
          disabled={isCodeSend}
          name="user_name"
          label={formFieldLabel('user_name')}
          rules={{
            required: t('field_errors.required'),
          }}
          showError
          maxLength="64"
        />
        <FieldBuilder.Email
          name="email"
          showError
          label={formFieldLabel('email')}
          rules={{
            required: t('field_errors.required'),
          }}
          disabled={isCodeSend}
        />
        <FieldBuilder.Phone
          showError
          name="mobile"
          label={t('registration.fields.phone_number')}
          hint={t('registration.field_hints.phone_number')}
          rules={{
            required: t('field_errors.required'),
            validate: formValidators.mobile(),
          }}
          disabled={isCodeSend}
          InputIcon={isValid && (
            <FormField.InputIcon>
              <StaticIcon name="filled-check" />
            </FormField.InputIcon>
          )}
        />
        {!isCodeSend && (
          <Typography
            variant="caption1"
            color="deepBlue3"
            tag="div"
          >
            <PrivacyFooter onChange={toggleCheckbox} checked={checked} />
          </Typography>
        )}
      </SubmitForm>
      {isCodeSend && (
        <ConfirmCode 
          mobile={mobile} 
          onBack={onBackClick} 
          leftTimeInSeconds={secondsUntilCanSendCode} 
          canSendCode={canSendCode}
          sendCode={sendCode}
          data={data}
        />
      )}
    </RegistrationLayout>
  );
};

export default RegistrationPage;
