import { useMountEffect } from '@hooks/useMountEffect';
import { bem } from '@lib/bem';
import { formValidators } from '@lib/form-validators';
import { t } from '@lib/i18n';

import { loginByCode, sendConfirmationCode } from '@api/handlers/auth';
import { paths } from '@shared/paths';
import {
  FieldBuilder, formatPhoneNumber, mobileFieldDefaultValue, FormField, 
} from '@ui/FieldBuilder';
import StaticIcon from '@ui/StaticIcon';
import { SubmitForm } from '@ui/SubmitForm';
import Typography from '@ui/Typography';
import PrivacyFooter from '@widgets/privacy-footer/ui/PrivacyFooter';
import { navigate } from 'gatsby';

import RegistrationLayout from 'routes/registration/ui/layout';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import Notice from '@lib/notifications';
import './style.scss';

const formatDuration = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const ConfirmCodeFooter = () => {
  return (
    <div>
      <div><Typography className="text-deep-blue-5" variant="caption1">{t('registration.confirm_code_footer.dont_come_sms')}</Typography></div>
      <div><Typography className="text-deep-blue-5" variant="caption1">{t('registration.confirm_code_footer.check_phone')}</Typography></div>
    </div>
  );
};

const {
  block,
  element,
} = bem('registration');

const ConfirmCodePage = ({
  onCodeReceive,
  leftTimeInSeconds,
  mobile,
  canSendCodeAgain,
  onBack,
}) => {
  const [isWrongCode, setIsWrongCode] = useState(false);
  const confirmForm = useForm({ mode: 'onChange' });
  const {
    trigger,
    control,
    handleSubmit,
  } = confirmForm;

  useMountEffect(async () => {
    await trigger();
  }, []);

  const { isValid } = useFormState({ control });

  const handleLogin = useCallback(async (values) => {
    const response = await loginByCode({
      data: {
        mobile: mobile.number,
        code: values.verifyCode,
      },
    });
    if (!response.ok) {
      if (response.responseBody && response.responseBody.code === 205) {
        setIsWrongCode(true);
      } else {
        const isOffline = window.navigator.onLine === false;
        Notice.error(t(isOffline ? 'errors.offline' : 'registration.field_errors.wrong_code'), {
          className: 'registration-layout-toast',
        });
      }
    } else {
      navigate(paths.registrationDetails.path);
    }
  }, [mobile]);

  const extraActionText = useMemo(() => {
    if (leftTimeInSeconds) {
      return `${t('registration.buttons.receive_code_again_through')} ${formatDuration(leftTimeInSeconds)}`;
    }
    if (leftTimeInSeconds === 0) {
      return t('registration.buttons.receive_code_again');
    }
  }, [leftTimeInSeconds]);

  const verifyCodeLabel = useMemo(() => {
    return (
      <div {...element('mobile-label', {}, 'items-center')}>
        <Typography className="font-medium" variant="caption1" color="deepBlue2">{t('registration.cta.number_text')}</Typography>
        <Typography className="font-medium" variant="caption1" color="deepBlue2" {...element('mobile')}>
          {formatPhoneNumber(mobile)}
        </Typography>
        <StaticIcon name="edit" {...element('mobile-label-icon')} onClick={onBack} />
      </div>
    );
  }, [mobile, onBack]);

  useEffect(() => {
    if (isValid) {
      handleSubmit(handleLogin)();
    }
  }, [handleLogin, handleSubmit, isValid]);

  const handleExtraAction = useCallback(() => {
    onCodeReceive({ mobile });
    return false;
  }, [onCodeReceive]);

  return (
    <RegistrationLayout
      {...block()}
      seo={t('seo.registration')}
      cta={t('registration.cta.confirmation_code')}
      Footer={<ConfirmCodeFooter />}
      stepIndex={1}
      extraActionText={extraActionText}
      onExtraAction={canSendCodeAgain ? handleExtraAction : null}
      extraActionRoute={canSendCodeAgain ? '#' : ''}
      withScrollEffect={false}
    >
      <SubmitForm
        form={confirmForm}
        onSubmit={handleLogin}
        submitText={t('common.continue')}
      >
        <FieldBuilder.VerifyCode
          name="verifyCode"
          label={verifyCodeLabel}
          showError={isWrongCode}
          rules={{
            pattern: /\d{4}/,
            required: 'wrong',
          }}
        />
      </SubmitForm>
    </RegistrationLayout>
  );
};

ConfirmCodePage.defaultProps = {
  leftTimeInSeconds: null,
};

ConfirmCodePage.propTypes = {
  onCodeReceive: PropTypes.func.isRequired,
  leftTimeInSeconds: PropTypes.number,
  mobile: PropTypes.string.isRequired,
  canSendCodeAgain: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
};

const PhoneConfirmation = () => {
  const form = useForm({ mode: 'onChange' });
  const { seo } = useSeo(REGISTRATION_PAGE.en);

  useMountEffect(async () => {
    await form.trigger();
  });

  const [, setCodeData] = useState();

  const [leftTimeInSeconds, setLeftTimeInSeconds] = useState();
  const startTimer = useCallback((initialValue) => {
    setLeftTimeInSeconds(initialValue);
    const interval = setInterval(() => {
      setLeftTimeInSeconds((oldValue) => {
        if (oldValue === 0) {
          clearInterval(interval);
          return 0;
        }
        return oldValue - 1;
      });
    }, 1000);
  }, []);

  const [currentStep, setCurrentStep] = useState(0);
  const handleBack = useCallback(() => {
    setCurrentStep(0);
    setLeftTimeInSeconds(undefined);
  }, []);

  const [checked, setChecked] = useState(false);

  const toggleCheckbox = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const canSendCode = !leftTimeInSeconds || leftTimeInSeconds === 0;

  const handleCodeReceive = useCallback(async (values) => {
    if (canSendCode) {
      const {
        responseBody,
        ok,
      } = await sendConfirmationCode({
        data: {
          mobile: values.mobile.number,
          action: 'confirm_mobile',
        },
      });

      let error = '';
      if (!ok) {
        if (responseBody && responseBody.message) {
          error = responseBody.message;
        } else {
          error = t('registration.field_errors.failed_code_send');
        }
        Notice.error(error, { className: 'registration-layout-toast' });
      } else {
        setCodeData(responseBody.data);
        startTimer(responseBody.data.next_retry_timer);
        if (currentStep !== 1) {
          setCurrentStep(1);
        }
      }

      if (error) {
      // TODO form validation
      // return { mobile: error };
      }
    }
  }, [canSendCode, currentStep, startTimer]);

  const mobile = useWatch({
    control: form.control,
    name: 'mobile',
    defaultValue: mobileFieldDefaultValue,
  });

  const { isValid } = useFormState({ control: form.control });

  const steps = useMemo(() => {
    return [
      <RegistrationLayout
        cta={t('registration.cta.phone_number')}
        Footer={<PrivacyFooter onChange={toggleCheckbox} checked={checked} />}
        stepIndex={0}
        seo={seo}
        withScrollEffect={false}
      >
        <SubmitForm
          form={form}
          onSubmit={handleCodeReceive}
          submitText={t('registration.buttons.receive_code')}
          canSubmit={canSendCode && checked}
        >
          <FieldBuilder.Phone
            name="mobile"
            label={t('registration.fields.phone_number')}
            hint={t('registration.field_hints.phone_number')}
            rules={{
              required: true,
              validate: formValidators.mobile(),
            }}
            clearable
            InputIcon={isValid && (
              <FormField.InputIcon>
                <StaticIcon name="filled-check" />
              </FormField.InputIcon>
            )}
          />
        </SubmitForm>
      </RegistrationLayout>,
      <ConfirmCodePage
        onCodeReceive={handleCodeReceive}
        leftTimeInSeconds={leftTimeInSeconds}
        canSendCodeAgain={canSendCode && checked}
        onBack={handleBack}
        mobile={mobile}
      />,
    ];
  }, [canSendCode, form, handleBack, handleCodeReceive, leftTimeInSeconds, mobile, seo, checked]);

  return steps[currentStep];
};

export default PhoneConfirmation;
